import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const APropos = () => {
  return (
    <Layout>
      <SEO title="A propos" description="Découvrez Marougrav, une entreprise de gravure mécanique, gravure laser, impression et vente de consommables en Brabant Wallon." />
      <section className="About">
        <div className="wrapper container">
          <h2 className="mb-2">À propos</h2>
          <div className="row">
            <div className="col-md-12">
              <p>Chaque client étant unique, toutes nos créations sont personnalisées, tant pour les particuliers que pour les professionnels.</p>
              <p>Marougrav dispose de machine à graver permettant de produire sur :</p>
              <ul>
                <li>PVC</li>
                <li>Aluminium – Laiton</li>
                <li>Inox</li>
              </ul>
              <p>Et vous propose de réaliser vos projets en :</p>
              <ul>
                <li>Gravures endroit</li>
                <li>Gravures envers sur support transparent ou coloré</li>
                <li>Plaques professionnelles</li>
                <li>Plaques funéraires</li>
                <li>Stickers</li>
              </ul>
              <p>Lettrage vinyle pour :</p>
              <ul>
                <li>Panneaux de chantier – travaux publiques</li>
                <li>Publicités commerciales
                  <ul>
                    <li>Sur vitrines</li>
                    <li>Véhicules</li>
                  </ul>
                </li>
              </ul>
              <p>Système de fixation :</p>
              <ul>
                <li>Entretoises décoratives</li>
                <li>Supports de plaques</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default APropos;
